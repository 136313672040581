<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="updateGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              :placeholderText="$t('digite-o-nome-do-questionario')"
            />
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-show="questionnaire">
        <Grid
          ref="grid"
          :service="service"
          route_grid_path="/questionnaire_sent_email/grid"
          :hide_actions="true"
          :fetchOnMounted="false"
          :extraParams="gridParameters"
          :showExport="true"
          :exportFilename="`Relatório de Email Enviado - Convite - ${questionnaire && questionnaire.name}`"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
export default {
  components: { SelectSuggestion },

  data: () => ({
    service: null,
    questionnaire: null,
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().model.current_page = 0
        this.grid().fillCustomFilters(
          [
           {key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
  }
}
</script>
